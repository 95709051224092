import {
  Button,
  FormInputWrapper,
  Icon,
  Input,
  Modal,
  Row,
  Text,
} from "app/components";
import { GoogleLoginButton, useGoogleLogin } from "../components/GoogleLogin";
import { apiRequest, handleError } from "app/utils/apiRequests";
import { errorNotification, successNotification } from "app/utils/Notification";
import {
  frontlyLogoDark,
  getCurrentDomain,
  getUrlParameter,
} from "app/utils/utils";
import { get, isEmpty } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";

import AdminForm from "../components/AdminForm";
import Cookies from "js-cookie";
import { Checkbox as MUICheckbox } from "@mui/material";
import { colors } from "app/utils/theme";
import mixpanel from "mixpanel-browser";
import styled from "styled-components";
import { useState } from "react";

const features = [
  "Move faster with custom AI apps and automations",
  "Turn spreadsheets into powerful, visual dashboards",
  "Control the data users see with granular permissions",
];

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const AdminLogin = ({ type = "login" }) => {
  const navigate = useNavigate();

  const location = useLocation();
  const urlRef = getUrlParameter("ref", location);
  const via = Cookies.get("via") || getUrlParameter("via", location);
  const ref = Cookies.get("ref") || getUrlParameter("ref", location);

  const [isSendingPasswordEmail, setIsSendingPasswordEmail] = useState(false);

  const [isFetching, setIsFetching] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false);

  const [state, setState] = useState({
    email: "",
    password: "",
    first_name: "",
    last_name: "",
  });
  const [errors, setErrors] = useState({});

  const validate = () => {
    // Validate form values

    let errors = {};

    if (type === "signup") {
      // validate first name and last name
      if (state.first_name.length < 1) {
        errors["first_name"] = "First Name required";
      }
      if (state.last_name.length < 1) {
        errors["last_name"] = "Last Name required";
      }
    }

    if (!emailRegex.test(state.email)) {
      errors["email"] = "Valid email required";
    }

    if (state.password.length < 6) {
      errors["password"] = "Please enter a more secure password";
    }

    setErrors(errors);

    return isEmpty(errors);
  };

  const login = () => {
    const rewardfulReferral = get(window, ["Rewardful", "referral"]);
    // Make login request
    if (validate()) {
      setIsFetching(true);
      let finalData = {
        ...state,
        referral_source: ref || via,
        rewardful_referral_id: rewardfulReferral,
      };
      apiRequest
        .post(`/${type}/`, finalData)
        .then((response) => {
          handleSignupResponse(response);
        })
        .catch((e) => {
          const { message } = handleError(e);
          setErrors({ password: "Invalid password" });
          setIsFetching(false);
          errorNotification(message);
        });
    }
  };

  const handleSignupResponse = (response) => {
    const error = get(response, ["data", "error"]);
    if (error) {
      errorNotification(error);
      setIsFetching(false);
      setErrors({ password: "Invalid email or password" });
    } else {
      const token = get(response, ["data", "token"]);
      const responseType = get(response, ["data", "type"]);
      Cookies.set("accessToken", token, { expires: 30 });
      setIsFetching(false);

      const responseUser = get(response, ["data", "user"]);

      mixpanel.identify(responseUser.email);

      mixpanel.people.set({
        $email: responseUser.email,
        first_name: responseUser.first_name,
        last_name: responseUser.last_name,
      });
      if (type === "signup") {
        mixpanel.track("Create Account", {
          name: responseUser.first_name + " " + responseUser.last_name,
        });
      } else {
        mixpanel.track("Login");
      }

      navigate("/home");
    }
  };

  const inputPadding = window.innerWidth < 800 ? "9px" : "13px";

  const frontlyLogo = frontlyLogoDark;

  // GOOGLE LOGIN
  // useGoogleLogin(setIsFetching, (r) => handleSignupResponse(r));

  return (
    <PublicWrapper>
      <GoogleLoginButton />
      {showForgotPassword && (
        <Modal
          minWidth="420px"
          hide={() => setShowForgotPassword(false)}
          header={{
            title: "Forgot Password",
            description: "Enter your email to request a password reset link",
          }}
        >
          <AdminForm
            isFetching={isSendingPasswordEmail}
            submit={() => {
              setIsSendingPasswordEmail(true);
              apiRequest
                .post("/forgot_password/", {
                  email: state.email,
                  domain: getCurrentDomain(),
                })
                .then((r) => {
                  const error = get(r, ["data", "error"]);
                  if (error) {
                    errorNotification(error);
                  } else {
                    successNotification("Password reset email sent");
                    setShowForgotPassword(false);
                    setIsSendingPasswordEmail(false);
                    mixpanel.track("Reset Password");
                  }
                })
                .catch((error) => {
                  console.log("ERROR", error);
                  errorNotification(get(error, "data"));
                  setIsSendingPasswordEmail(false);
                });
            }}
            errors={errors}
            submitText={"Send Password Reset Email"}
            onChange={(k, v) => setState((s) => ({ ...s, [k]: v }))}
            sectionPadding="0px"
            fields={[
              {
                id: "email",
                componentId: "Input",
                label: "Email",
                value: state.email,
              },
            ]}
          />
        </Modal>
      )}

      <Container>
        <LeftContainer>
          <Headline>
            {type === "login" ? "Login To Frontly" : "Try Frontly Today"}
          </Headline>
          <Text
            data={{
              text: "Build internal tools in minutes with AI",
              fontSize: 30,
              fontWeight: 300,
              color: "white",
              margin: "0 0 30px 3px",
            }}
          />

          <FeaturesColumn>
            {features.map((f) => (
              <Row alignItems="center" gap="15px">
                <Icon
                  data={{ icon: "FiCheck", color: colors.grey1, size: 16 }}
                />
                <Text
                  data={{
                    text: f,
                    fontStyle: "bodyXl",
                    color: "rgba(255, 255, 255, 0.8)",
                    light: true,
                  }}
                />
              </Row>
            ))}
          </FeaturesColumn>
          {type === "signup" && urlRef === "producthunt" && (
            <PhBubble>
              <PhBubbleLogo src="https://res.cloudinary.com/frontly/image/upload/v1716997494/phlogo_kxsjcq.png" />
              <div>
                <PhBubbleTitle>Coming from Product Hunt?</PhBubbleTitle>
                <PhBubbleSubtitle>
                  Register in the next 24 hours to lock in 35% off all plans.
                  Only pay when you're ready to publish.
                </PhBubbleSubtitle>
              </div>
            </PhBubble>
          )}
        </LeftContainer>

        {type === "signup" && urlRef === "producthunt" && (
          <PhBubbleMobile>
            <PhBubbleLogo src="https://res.cloudinary.com/frontly/image/upload/v1716997494/phlogo_kxsjcq.png" />
            <div>
              <PhBubbleTitle>Coming from Product Hunt?</PhBubbleTitle>
              <PhBubbleSubtitle>
                Register in the next 24 hours to lock in 35% off all plans. Only
                pay when you're ready to publish.
              </PhBubbleSubtitle>
            </div>
          </PhBubbleMobile>
        )}

        <RightContainer>
          <FormContainer>
            <Logo src={frontlyLogo} />
            {type === "signup" && (
              <>
                <Row gap="10px" margin="10px 0 0 0">
                  <FormInputWrapper
                    label={"First Name"}
                    error={errors.first_name}
                    labelStyle="bodyMdMedium"
                  >
                    <Input
                      data={{
                        placeholder: "First Name",
                        padding: inputPadding,
                        borderRadius: "12px",
                        border: errors.first_name && "1px solid red",
                        width: "100%",
                        onChange: (v) =>
                          setState((s) => ({ ...s, first_name: v })),
                      }}
                    />
                  </FormInputWrapper>

                  <FormInputWrapper
                    label={"Last Name"}
                    error={errors.last_name}
                    labelStyle="bodyMdMedium"
                  >
                    <Input
                      data={{
                        placeholder: "Last Name",
                        padding: inputPadding,
                        borderRadius: "12px",
                        border: errors.last_name && "1px solid red",
                        width: "100%",
                        onChange: (v) =>
                          setState((s) => ({ ...s, last_name: v })),
                      }}
                    />
                  </FormInputWrapper>
                </Row>
              </>
            )}
            <FormInputWrapper
              label={"Email"}
              error={errors.email}
              labelStyle="bodyMdMedium"
            >
              <Input
                data={{
                  placeholder: "Email",
                  border: errors.email && "1px solid red",
                  padding: inputPadding,
                  borderRadius: "12px",
                  onChange: (v) => setState((s) => ({ ...s, email: v })),
                }}
              />
            </FormInputWrapper>
            <FormInputWrapper
              label={"Password"}
              error={errors.password}
              labelStyle="bodyMdMedium"
            >
              <Input
                data={{
                  label: "Password",
                  placeholder: "Password",
                  type: "password",
                  border: errors.password && "1px solid red",
                  padding: inputPadding,
                  borderRadius: "12px",
                  onChange: (v) => setState((s) => ({ ...s, password: v })),
                }}
              />
            </FormInputWrapper>

            {type === "signup" && (
              <Row alignItems="center" gap="10px" margin="5px 0 0 0">
                <MUICheckbox
                  color="primary"
                  checked={privacyPolicyAccepted}
                  onChange={() =>
                    setPrivacyPolicyAccepted(!privacyPolicyAccepted)
                  }
                  value={privacyPolicyAccepted}
                  style={{ padding: "0px" }}
                  size="small"
                />

                <div style={{ fontSize: 14 }}>
                  I agree to Frontly's{" "}
                  <span
                    onClick={() =>
                      window.open(
                        "https://help.frontly.ai/en/articles/8049500-frontly-privacy-policy"
                      )
                    }
                    style={{
                      color: colors.primary,
                      fontWeight: 600,
                      cursor: "pointer",
                    }}
                  >
                    Privacy Policy
                  </span>
                </div>
              </Row>
            )}

            <Button
              data={{
                text: type === "signup" ? "Create Account" : "Login",
                onClick: login,
                width: "100%",
                padding: inputPadding,
                borderRadius: "12px",
                disabled: !privacyPolicyAccepted && type === "signup",
                size: "large",
                margin: "5px 0 0 0",
                isFetching,
              }}
            />

            <Row justifyContent="center" gap="10px" margin="10px 0 0 0">
              <Text
                data={{
                  text:
                    type === "login"
                      ? "Don't have an account?"
                      : "Already have an account?",
                  color: colors.grey3,
                  fontStyle: "bodyMd",
                }}
              />
              <Text
                data={{
                  text: type === "login" ? "Sign Up" : "Login",
                  color: colors.primary,
                  fontStyle: "headingSm",
                  onClick: () =>
                    navigate(type === "login" ? "/signup" : "/login"),
                }}
              />
            </Row>
          </FormContainer>
          <Text
            data={{
              text: "Forgot password?",
              onClick: () => setShowForgotPassword(true),
              fontStyle: "bodyLg",
              margin: "25px 0 15px 0",
              color: "#ffffff99",
            }}
          />
        </RightContainer>
      </Container>
    </PublicWrapper>
  );
};

export default AdminLogin;

const PhBubble = styled.div`
  display: flex;
  align-items: center;
  background: white;
  color: black;
  padding: 15px;
  border-radius: 20px;
  gap: 15px;
  margin-top: 40px;
`;

const PhBubbleMobile = styled.div`
  display: flex;
  align-items: center;
  background: white;
  color: black;
  padding: 15px;
  border-radius: 20px;
  gap: 15px;
  display: none;
  @media (max-width: 1000px) {
    display: flex;
  }
`;

const PhBubbleLogo = styled.img`
  height: 50px;
  cursor: pointer;
  @media (max-width: 1000px) {
    height: 30px;
  }
`;

const PhBubbleTitle = styled.div`
  font-size: 22px;
  font-weight: 800;
  cursor: pointer;
  @media (max-width: 1000px) {
    font-size: 16px;
  }
`;

const PhBubbleSubtitle = styled.div`
  font-size: 18px;
  font-weight: 400;
  @media (max-width: 1000px) {
    font-size: 14px;
  }
`;

const PublicWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  background: linear-gradient(-45deg, #2841ff, #375eee);
  font-family: "Manrope", "Roboto", sans-serif;
  @media (max-width: 1000px) {
    align-items: flex-start;
  }
`;

const Container = styled.div`
  padding: 30px;
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 150px;
  @media (max-width: 1000px) {
    flex-direction: column;
    gap: 40px;
    padding: 20px;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 550px;
  @media (max-width: 1000px) {
    display: none;
  }
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FeaturesColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (max-width: 1000px) {
    display: none;
  }
`;

const Headline = styled.div`
  font-size: 60px;
  font-weight: 500;
  color: white;
  margin-bottom: 20px;
  @media (max-width: 1000px) {
    font-size: 42px;
  }
`;

const FormContainer = styled.div`
  background: white;
  padding: 40px;
  display: flex;
  flex-direction: column;
  border-radius: 25px;
  width: 390px;
  gap: 16px;
  @media screen and (max-width: 800px) {
    width: 100%;
    gap: 15px;
    padding: 25px;
  }
`;

const Logo = styled.img`
  height: 43px;
  width: 100%;
  object-fit: contain;
  @media screen and (max-width: 800px) {
    height: 45px;
  }
`;
