import { Button, Modal } from "app/components";
import { get, startCase } from "lodash";
import { rAiView, rSavedSpreadsheets, rTranslations } from "app/utils/recoil";
import { useRecoilState, useRecoilValue } from "recoil";

import EditorGrid from "app/components/EditorGrid";
import useSpreadsheetRequests from "app/useSpreadsheetRequests";
import { useState } from "react";

// import { TableComponent } from "app/renderingApp/blocks/Table";

const AiView = () => {
  const [aiView, setAiView] = useRecoilState(rAiView);
  const dataSources = useRecoilValue(rSavedSpreadsheets);

  const { createMultipleRecords } = useSpreadsheetRequests();

  const [isSaving, setIsSaving] = useState(false);

  const translations = useRecoilValue(rTranslations);

  // No View
  if (!aiView) {
    return <></>;
  }

  const type = get(aiView, "type", "multiple_records");

  const records = get(aiView, "records", []);

  const dataSource = dataSources.find((ds) => ds.id === aiView.dataSource);

  const headers = get(dataSource, "headers", []).filter(
    (h) => h !== "frontly_id"
  );

  // Multiple Records
  if (type === "multiple_records") {
    return (
      <Modal
        hide={() => setAiView(null)}
        minWidth="900px"
        header={{
          title: get(translations, "aiViewTitle", "AI Response"),
          description: get(
            translations,
            "aiViewDescription",
            "Review and edit the generated records before saving"
          ),
        }}
      >
        <EditorGrid
          data={{
            newObject: {},
            onChange: (newRecords) =>
              setAiView({
                ...aiView,
                records: newRecords,
              }),
            value: records,
            columns: headers.map((h) => ({
              key: h,
              label: startCase(h),
              width: "150px",
              componentId: "Input",
            })),
          }}
        />

        {/* <TableComponent
          data={{
            preventDarkMode: true,
            rows: records.map((r) => ({
              ...r,
              onClick: () => null,
            })),
            columns: headers.map((h) => ({
              key: h,
              columnType: "text",
            })),
          }}
        /> */}

        <Button
          data={{
            isFetching: isSaving,
            text: get(translations, "aiViewSaveButton", "Save Records"),
            onClick: () => {
              setIsSaving(true);
              createMultipleRecords({
                sheetId: dataSource.id,
                rows: records,
              }).then(() => {
                setIsSaving(false);
                setAiView(null);
              });
            },
          }}
        />
      </Modal>
    );
  }

  // Single Record
  else if (type === "single") {
    return <div>Single</div>;
  }

  return null;
};

export default AiView;
