import { Button, Text } from "app/components";
import {
  getPixels,
  handleClientSidePagination,
  isFrontlyAdmin,
} from "app/utils/utils";
import {
  rApp,
  rLiveSpreadsheets,
  rPagination,
  rSavedSpreadsheets,
  rTranslations,
  refreshBlockIdsSelector,
} from "app/utils/recoil";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import { Pagination } from "@mui/material";
import { colors } from "app/utils/theme";
import { get } from "lodash";
import styled from "styled-components";

const PaginationContainer = styled.div`
  padding: ${(p) => p.padding};
  border-top: ${(p) =>
    p.hideBorder
      ? "0px"
      : `1px solid ${p.darkMode ? colors.darkModeLightBorder : "#e1e3e5"}`};
`;

export function PaginationWrapper({
  children,
  items = [],
  itemsPerPage,
  showFirstButton = false,
  showLastButton = false,
  hideBorder = false,
  padding = "0px",
  noResultsPadding = "0px",
  isFetching = false,
  noResultsName = "results",
  darkMode = false,
  block,
}) {
  const [recoilPagination, setPagination] = useRecoilState(rPagination);
  const setRefreshBlockIds = useSetRecoilState(refreshBlockIdsSelector);

  const spreadsheets = useRecoilValue(rLiveSpreadsheets);

  const translations = useRecoilValue(rTranslations);

  const isReusable = get(block, "componentId") === "Custom";

  const savedSpreadsheets = useRecoilValue(rSavedSpreadsheets);

  const matchingSheet = savedSpreadsheets.find(
    (s) => s.id === get(block, "spreadsheet")
  );
  const service = get(matchingSheet, "service");

  const isDataBase = ["supabase", "airtable"].includes(service);

  const livePagination = get(spreadsheets, "pagination", {});
  const blockPagination = get(livePagination, get(block, "id"), {});

  const [localPage, setLocalPage] = useState(1);

  // Used to track the offset history for the airtable pagination
  const [offsetHistory, setOffsetHistory] = useState([]);

  let paginatedItems = [...items];

  const itemsLength = isDataBase
    ? get(blockPagination, "count", 0)
    : get(items, "length", 0);

  // If user is on page 2 or greater and results are less than a page, reset to page one
  useEffect(() => {
    if (!isDataBase) {
      if (localPage > 1 && itemsLength <= itemsPerPage) {
        setLocalPage(1);
      }
    }
  }, [itemsLength]);

  useEffect(() => {
    if (!isDataBase) {
      setLocalPage(1);
    }
    return () => {};
  }, [itemsPerPage]);

  useEffect(() => {
    if (isDataBase) {
      if (blockPagination.page) {
        setLocalPage(blockPagination.page);
      }
    }
  }, []);

  const showPagination =
    isDataBase || (itemsLength > itemsPerPage && itemsPerPage);

  const clientSidePageCount = Number.isFinite(
    Math.ceil(itemsLength / itemsPerPage)
  )
    ? Math.ceil(itemsLength / itemsPerPage)
    : 0;

  if (!isDataBase && itemsPerPage) {
    paginatedItems = handleClientSidePagination({
      items: items,
      currentPage: localPage,
      itemsPerPage: itemsPerPage,
    });
  }

  const paginationProps = {
    className: "mui-pagination",
    showFirstButton: showFirstButton,
    showLastButton: showLastButton,
    variant: "outlined",
    shape: "rounded",
    color: "primary",
  };

  const activeApp = useRecoilValue(rApp);

  const styling = get(activeApp, "styling", {});
  const blockBorderRadius = isFrontlyAdmin
    ? null
    : get(styling, "formInputRadius");

  let pagination = (
    <Pagination
      {...paginationProps}
      page={localPage}
      count={clientSidePageCount}
      onChange={(e, page) => {
        if (isDataBase) {
          setPagination({
            ...recoilPagination,
            [block.id]: {
              ...blockPagination,
              page: page,
            },
          });
          setRefreshBlockIds([block.id]);
          setLocalPage(page);
        } else {
          setLocalPage(page);
        }
      }}
      sx={{
        "& .MuiPaginationItem-root": {
          color: darkMode ? colors.darkModeLightGrey : colors.grey4, // For text color
          borderRadius: getPixels(blockBorderRadius),
          borderColor: darkMode && colors.darkModeLightBorder,
        },
        "& .MuiPaginationItem-root.Mui-selected": {
          backgroundColor: darkMode
            ? colors.darkModeLightBackground
            : colors.grey1, // Background color for the currently active page
          borderColor: darkMode ? colors.darkModeLightGrey : colors.grey3,
          color: darkMode ? "white" : colors.grey4, // For text color
        },
      }}
    />
  );

  if (service === "airtable") {
    const offset = get(blockPagination, "offset");

    const handleAirtableNext = () => {
      const newOffset = get(blockPagination, "offset");
      setPagination({
        ...recoilPagination,
        [block.id]: {
          ...blockPagination,
          offset: newOffset,
        },
      });
      setRefreshBlockIds([block.id]);

      // Add the new offset to the history if it's not already there
      if (!offsetHistory.includes(newOffset)) {
        setOffsetHistory([...offsetHistory, newOffset]);
      }
    };

    const handleAirtablePrevious = () => {
      const currentOffsetIndex = offsetHistory.indexOf(
        get(blockPagination, "offset")
      );
      const previousOffset =
        currentOffsetIndex > 0 ? offsetHistory[currentOffsetIndex - 1] : null;

      setPagination({
        ...recoilPagination,
        [block.id]: {
          ...blockPagination,
          offset: previousOffset,
        },
      });
      setRefreshBlockIds([block.id]);

      // Remove the last offset if going back
      if (currentOffsetIndex > 0) {
        setOffsetHistory(offsetHistory.slice(0, currentOffsetIndex));
      }

      if (!previousOffset) {
        setOffsetHistory([]);
      }
    };

    pagination = (
      <AirtablePaginationContainer>
        <Button
          data={{
            text: "Previous",
            fontStyle: "bodyLg",
            type: "basic",
            disabled: !offsetHistory.length,
            onClick: handleAirtablePrevious,
          }}
        />
        <Button
          data={{
            text: "Next",
            fontStyle: "bodyLg",
            type: "basic",
            onClick: handleAirtableNext,
            disabled: !offset,
          }}
        />
      </AirtablePaginationContainer>
    );
  }

  return (
    <>
      {!isFetching && get(paginatedItems, "length", 0) === 0 && (
        <Text
          data={{
            text:
              get(translations, "noResultsMatch") ||
              `No ${noResultsName} match this search.`,
            fontStyle: "bodyLg",
            padding: noResultsPadding,
            color: darkMode ? "lightGrey" : "grey4",
          }}
        />
      )}

      {isFetching
        ? children([])
        : get(paginatedItems, "length", 0) > 0 && children(paginatedItems)}

      {!isFetching && showPagination ? (
        <>
          {isReusable && pagination}

          {!isReusable && (
            <PaginationContainer
              hideBorder={hideBorder}
              padding={padding}
              darkMode={darkMode}
            >
              {pagination}
            </PaginationContainer>
          )}
        </>
      ) : null}
    </>
  );
}

export default PaginationWrapper;

const AirtablePaginationContainer = styled.div`
  display: flex;
  gap: 15px;
  width: 100%;
`;
