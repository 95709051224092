import { get, indexOf, isNil } from "lodash";
import {
  getUrlParameter,
  isFrontlyAdmin,
  parseDateWithFormatObject,
  safeArray,
  safeParseFloatOrInt,
  safeString,
} from "app/utils/utils";
import {
  rApp,
  rAppDateFormat,
  rContentLibrary,
  rFormState,
  rLiveSpreadsheets,
  rLocalState,
  rUser,
} from "app/utils/recoil";
import { useRecoilState, useRecoilValue } from "recoil";

import moment from "moment";
import { useLocation } from "react-router-dom";
import useModalStateData from "app/useModalStateData";
import usePage from "app/utils/usePage";

const useDynamicText = () => {
  const appDateFormat = useRecoilValue(rAppDateFormat);

  const spreadsheets = useRecoilValue(rLiveSpreadsheets);

  const contentLibrary = useRecoilValue(rContentLibrary);

  const { modalStack, lastItem } = useModalStateData();

  const getActiveStackItem = (i) => {
    const stackBlockId = get(i, "blockId");
    const stackItemId = get(i, "itemId");
    const activeSheet = safeArray(spreadsheets, stackBlockId);
    const activeDetailItem = activeSheet.find(
      (x) => x.frontly_id === stackItemId
    );
    return activeDetailItem;
  };

  // Parent - The record that triggered the current detail view
  const activeStackItem = getActiveStackItem(lastItem);

  // GrandParent - If exists, the record that triggered the detail view 2 layers up
  const lastItemGrandparent = get(modalStack, modalStack.length - 2);
  const activeStackGrandparent = getActiveStackItem(lastItemGrandparent);

  let dataSources = {
    spreadsheets,
  };

  if (activeStackItem) {
    dataSources = {
      ...dataSources,
      detail: activeStackItem,
      parent: activeStackItem,
    };
  }

  if (activeStackGrandparent) {
    dataSources = {
      ...dataSources,
      detailParent: activeStackGrandparent,
      grandparent: activeStackGrandparent,
    };
  }
  //

  const page = usePage();

  const activeApp = useRecoilValue(rApp);

  const [formState, setFormState] = useRecoilState(rFormState);

  const user = useRecoilValue(rUser);

  const localState = useRecoilValue(rLocalState);

  const blocks = get(page, "blocks", []);

  const location = useLocation();

  const activeBlockId = get(lastItem, "blockId");
  const activeItemId = get(lastItem, "itemId");
  const activeSheet = get(spreadsheets, activeBlockId, []);
  const activeDetailItem = activeSheet.find(
    (x) => x.frontly_id === activeItemId
  );

  const getIncrementLocation = (parts) => {
    const incrementIndex = indexOf(parts, "increment");
    const decrementIndex = indexOf(parts, "decrement");

    let action = null;
    let index = null;

    if (incrementIndex > -1) {
      action = "increment";
      index = incrementIndex;
    } else if (decrementIndex > -1) {
      action = "decrement";
      index = decrementIndex;
    }

    return { action, index };
  };

  function getNestedValue(source, parts, fallback) {
    return (
      parts.reduce((acc, part) => (acc ? acc[part] : undefined), source) ||
      fallback
    );
  }

  function handleSpreadsheet(ds, parts) {
    const spreadsheetId = parts[1];
    const cellName = parts[2];
    return getNestedValue(ds, [
      "spreadsheets",
      `cell__${spreadsheetId}`,
      cellName,
    ]);
  }

  const returnResolvedValue = (data) => {
    const { value, parts, data: originalProcessingData } = data;
    // HANDLE INCREMENT AND DECREMENT

    const { action, index } = getIncrementLocation(parts);

    if (!action || parts.length < index) {
      // Value is a variable, run process again
      if (!isFrontlyAdmin && safeString(value).includes("{{")) {
        const newValue = processDynamicText({
          ...originalProcessingData,
          text: value,
        });

        if (typeof newValue === "object") {
          return JSON.stringify(newValue, null, 2); // You can adjust this depending on how you want to return objects
        } else {
          return newValue;
        }
      }

      if (typeof value === "object") {
        return JSON.stringify(value, null, 2);
      } else {
        return value;
      }
    }

    const valueChange = parseInt(parts[index + 1]);
    const currentValue = safeParseFloatOrInt(value);

    if (action === "increment") return currentValue + valueChange;
    if (action === "decrement") return currentValue - valueChange;

    return value;
  };

  function getRowCount(ds, firstPart) {
    return getNestedValue(ds, [firstPart, "frontly_data", "row_count"]);
  }

  const processDynamicText = (data) => {
    if (!data) return null;

    const {
      text,
      context,
      reusableBlockId,
      skipRecordFrontlyId = false,
      skipGoogleSheetCell = false,
      skipCustomVariable = false,
    } = data;

    if (!text) return text;

    const matchingBlock = reusableBlockId
      ? blocks.find((b) => b.id === reusableBlockId)
      : null;

    const pattern = /\{\{\s*(.*?)\s*(?:\|\|\s*(.*?))?\s*\}\}/g;

    const { inputDate = "YYYY-MM-DD" } = appDateFormat || {};

    const timeNow = parseDateWithFormatObject({
      value: moment(),
      formatObject: appDateFormat,
    });

    // Because recoil updates are not instant, this allows conditional steps to depend on localState updates in previous steps
    const tempLocalState = get(context, "tempLocalState", {});
    let mergedLocalState = {
      ...localState,
      ...tempLocalState,
    };

    const userGroups = get(user, ["user_groups", activeApp.id], []);

    const userGroupsString = userGroups
      .map((userGroupId) => {
        const activeAppGroups = safeArray(activeApp, "user_groups");
        const matchingGroup = activeAppGroups.find((g) => g.id === userGroupId);

        if (matchingGroup) {
          return get(matchingGroup, "name");
        }

        return userGroupId;
      })
      .join(", ");

    // Update form context with form state
    let formContext = get(context, "form", {});

    Object.keys(formState).forEach((key) => {
      if (key && !isNil(key) && key !== "undefined") {
        // Only overwrite the formContext if the key is not already set
        const currentState = get(formContext, key);
        if (!currentState) {
          const fieldKey = key.includes("-") ? key.split("-")[1] : key;
          formContext[fieldKey] = formState[key];
        }
      }
    });

    const contentLibraryMap = contentLibrary.reduce((acc, item) => {
      acc[item.key] = item.data;
      return acc;
    }, {});

    const ds = {
      time: {
        now: timeNow,
        today: moment().format(inputDate),
      },
      detail: activeDetailItem,
      record: get(context, "repeatingRecord") || get(dataSources, "record"), // TODO - test this with normal 'record' first
      ...dataSources,
      ...context,
      user: {
        user_group_names: userGroupsString,
        ...user,
        ...get(context, "user"),
      },
      localState: mergedLocalState,
      // Add the matching block to the data sources to power variables for reusable blocks
      input: {
        ...get(context, "input", {}),
        ...get(matchingBlock, "inputs", {}),
      },
      form: formContext,
      content: contentLibraryMap,
    };

    return text
      .toString()
      .replace(pattern, function (match, key, defaultValue) {
        // let fallback = isFrontlyAdmin ? `{{ ${key} }}` : defaultValue || "";

        let fallback = isFrontlyAdmin
          ? defaultValue || `{{ ${key} }}` || ""
          : defaultValue || "";

        const parts = key.split(".").map((p) => (isNaN(p) ? p : parseInt(p)));
        let partsBeforeIncrement = [...parts];

        const { action, index } = getIncrementLocation(parts);
        if (action && index) {
          // get only the parts of the 'parts' array that are before the increment/decrement
          partsBeforeIncrement = parts.slice(0, index);
        }

        switch (parts[0]) {
          case "input":
            const resolvedVariable = getNestedValue(ds, parts, fallback);

            // TODO - This works but need to understand why it's needed compared to just using the returnResolvedValue function
            if (resolvedVariable.includes("record")) {
              const finalVal = processDynamicText({
                text: resolvedVariable,
                context,
                reusableBlockId,
              });

              return finalVal;
            }

            return returnResolvedValue({
              data,
              value: resolvedVariable,
              parts,
            });

          case "windowWidth":
            return window.innerWidth;
          case "custom":
            if (skipCustomVariable) {
              return `{{ ${parts.join(".")} }}`;
            }

            // Get parts array
            let partsArray = [];
            // Custom variable getting a specific object key
            if (partsBeforeIncrement.length === 3) {
              partsArray = [partsBeforeIncrement[1], partsBeforeIncrement[2]];
            }
            // Custom variable getting the whole object
            if (partsArray.length === 0 && partsBeforeIncrement.length >= 2) {
              partsArray = partsBeforeIncrement[1];
            }

            let mergedCustomSources = {
              ...get(ds, ["spreadsheets", "custom_variables"], {}),
              ...get(ds, "custom", {}),
            };

            return returnResolvedValue({
              data,
              value: get(mergedCustomSources, partsArray, fallback),
              parts,
            });

          case "block":
            if (parts.length === 3) {
              const matchingBlock = blocks.find((b) => b.id === parts[1]);
              const matchingSheetData = get(
                spreadsheets,
                get(matchingBlock, "id")
              );

              return returnResolvedValue({
                data,
                value: get(matchingSheetData, parts[2], fallback),
                parts,
              });
            }

            if (parts.length === 2) {
              const matchingBlock = blocks.find((b) => b.id === parts[1]);

              const matchingSheetData = get(
                spreadsheets,
                get(matchingBlock, "id")
              );

              return returnResolvedValue({
                data,
                value: matchingSheetData,
                parts,
              });
            }
            break;

          case "user":
            if (parts[1] === "user_groups") {
              const userGroupIds = safeArray(
                get(user, ["user_groups", get(activeApp, "id")])
              );
              const groupNames = safeArray(activeApp, "user_groups")
                .filter((x) => userGroupIds.includes(x.id))
                .map((x) => x.name);
              return groupNames.join(", ");
            }
            break;
          case "record":
            if (parts[1] === "frontly_id" && skipRecordFrontlyId)
              return "{{record.frontly_id}}";
            break;
          case "row_count":
            return "{{row_count}}";
          case "spreadsheets":
            if (skipGoogleSheetCell) {
              return `{{ ${parts.join(".")} }}`;
            }

            const sheetVal = handleSpreadsheet(ds, parts);

            if (sheetVal) {
              return returnResolvedValue({
                data,
                value: sheetVal,
                parts,
              });
            }

            if (isFrontlyAdmin) {
              const cellName = parts[2];
              return cellName;
            }

            return "";
          case "params":
            const v = getUrlParameter(parts[1], location);
            return returnResolvedValue({
              data,
              value: v || fallback,
              parts,
            });
          case "env":
            return `{{ ${key} }}`;
        }

        if (parts[0] === "time" && parts[1] === "custom") {
          const customTimeFormat = parts[2];
          const customTime = moment().format(customTimeFormat);

          // Handle time modifiers
          if (parts.length === 5) {
            const mod = parts[3];

            const modParts = mod.split("_");
            const modType = modParts[0];
            const modClass = modParts[1];

            const modAmount = parseInt(parts[4]);

            if (modType === "add") {
              return moment().add(modAmount, modClass).format(customTimeFormat);
            } else if (modType === "subtract") {
              return moment()
                .subtract(modAmount, modClass)
                .format(customTimeFormat);
            } else {
              return customTime;
            }
          }

          return customTime;
        }

        // Handle Row Count
        if (parts.length === 2 && parts[1] === "row_count") {
          return returnResolvedValue({
            data,
            value: getRowCount(ds, parts[0]) || fallback,
            parts,
          });
        }

        const v = getNestedValue(ds, partsBeforeIncrement, fallback);

        return returnResolvedValue({ data, value: v, parts });
      });
  };

  return { processDynamicText };
};

export default useDynamicText;
