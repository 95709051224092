import { Column, Row, Text } from "app/components";
import SettingsSection, { CardSection } from "./SettingsSection";
import { get, isEmpty, startCase } from "lodash";
import { rApp, rSubscription } from "app/utils/recoil";
import { useRecoilState, useRecoilValue } from "recoil";

import AdminForm from "../components/AdminForm";
import { apiRequest } from "app/utils/apiRequests";
import { colors } from "app/utils/theme";
import { successNotification } from "app/utils/Notification";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const Integrations = () => {
  const [app, setApp] = useRecoilState(rApp);

  const subscription = useRecoilValue(rSubscription);

  const currentPlan = get(subscription, "plan");
  const aiLimit = get(subscription, "ai_requests_limit");

  const [changes, setChanges] = useState({});
  const [aiChanges, setAIChanges] = useState(false);
  const [mailgunChanges, setMailgunChanges] = useState(false);
  const [makeChanges, setMakeChanges] = useState(false);
  const [cloudinaryChanges, setCloudinaryChanges] = useState(false);
  const [actionChanges, setActionChanges] = useState(false);

  const showApiKey =
    [
      "frontly_unlimited",
      "team",
      "growth",
      "basic",
      "frontly_x_appsumo_tier4",
      "frontly_x_appsumo_tier5",
      "professional",
      "enterprise",
    ].includes(currentPlan) ||
    aiLimit >= 500 ||
    get(subscription, "amount") > 290;

  const mailgunFields = [
    {
      id: "mailgun_api_key",
      hint: "A secure token used by Frontly to make requests to your Mailgun account. This will be used to send emails when using the 'Send Email' action or the Forgot Password email.",
      type: "password",
      value: get(app, "mailgun_api_key"),
    },
    {
      id: "mailgun_domain",
      placeholder: "yourdomain.com",
      hint: "The custom domain you have set up in Mailgun. This is the domain that will be used to send emails from your Frontly account. Should be something like 'yourdomain.com'",
      value: get(app, "mailgun_domain"),
    },
    {
      id: "mailgun_region",
      hint: "If your business is located outside of the US, you may need to add a region. For example, if your account is in europe, you will need to enter 'eu'",
      value: get(app, "mailgun_region"),
    },
  ];

  const makeFields = [
    {
      id: "make_organization_id",
      hint: "Your Make organization ID",
      value: get(app, "make_organization_id"),
    },
    {
      id: "make_api_token",
      hint: "A secure token used by Frontly to make requests to your Make account.",
      type: "password",
      value: get(app, "make_api_token"),
    },
  ];

  const cloudinaryFields = [
    {
      id: "cloudinary_cloud_name",
      hint: "The cloudinary-provided 'cloud name' which acts as a unique ID for your cloudinary account",
      value: get(app, "cloudinary_cloud_name"),
    },
    {
      id: "cloudinary_preset",
      hint: "A cloudinary preset to use for uploads. This allows you to adjust settings like cropping, quality, and more.",
      displayCondition: () => get(app, "cloudinary_cloud_name"),
      value: get(app, "cloudinary_preset"),
    },
    {
      id: "cloudinary_max_file_size",
      hint: "Adjust the maximum file size for uploads. Note: This is in MB.",
      componentId: "Select",
      hideEmptyItem: true,
      displayCondition: () => get(app, "cloudinary_cloud_name"),
      value: get(app, "cloudinary_max_file_size") || 5,
      options: [
        {
          label: "2MB",
          value: 2,
        },
        {
          label: "5MB",
          value: 5,
        },
        {
          label: "10MB",
          value: 10,
        },
        {
          label: "20MB",
          value: 20,
        },
        {
          label: "50MB",
          value: 50,
        },
        {
          label: "100MB",
          value: 100,
        },
      ],
    },
  ];

  const actionFields = [
    {
      id: "show_make_action",
      componentId: "Switch",
      description:
        "Enable the Make action in the Actions interface. Note: This is not needed for most use-cases.",
      value: get(app, "show_make_action"),
    },
    {
      id: "show_zapier_action",
      componentId: "Switch",
      description:
        "Enable the Zapier action in the Actions interface. Note: This is not needed for most use-cases.",
      value: get(app, "show_zapier_action"),
    },
  ];

  const aiFields = [
    {
      id: "open_ai_api_key",
      label: "OpenAI API Key",
      description: "Note: Requires a credit card on file in the OpenAI system.",
      hint: "Your OpenAI API key. Used to enable the OpenAI action within Frontly.",
      type: "password",
      value: get(app, "open_ai_api_key"),
    },
    {
      id: "ai_context_beta",
      label: "Enable AI Block (Beta)",
      componentId: "Switch",
      description:
        "WARNING: This feature uses more OpenAI tokens than other AI actions because it takes in context from your selected spreadsheet. Please review our documentation before using.",
      displayCondition: () => get(app, "open_ai_api_key"),
      value: get(app, "ai_context_beta"),
    },
    {
      componentId: "Button",
      text: "Read the AI Block article",
      icon: "FiHelpCircle",
      displayCondition: () => get(app, "open_ai_api_key"),
      onClick: () =>
        window.open(
          "https://help.frontly.ai/en/articles/9409373-ai-block-beta"
        ),
      type: "basic",
    },
  ];

  const save = (section) => {
    if (section === "ai") {
      setAIChanges(false);
    }
    if (section === "mailgun") {
      setMailgunChanges(false);
    }
    if (section === "make") {
      setMakeChanges(false);
    }
    if (section === "cloudinary") {
      setCloudinaryChanges(false);
    }
    if (section === "action") {
      setActionChanges(false);
    }

    successNotification("Saved");
    apiRequest.post("/app_settings/", changes);
    setChanges({});
  };

  const navigate = useNavigate();

  return (
    <div>
      <Text
        data={{
          text: "Integrations",
          fontStyle: "headingLg",
          margin: "0 0 10px 0",
        }}
      />
      <Text
        data={{
          text: "Configure various integrations with other services.",
          fontStyle: "bodyMd",
          color: colors.grey3,
          margin: "0 0 20px 0",
        }}
      />

      <Row gap="50px">
        <Column gap="25px" style={{ width: "400px" }}>
          <CardSection>
            <Text
              data={{
                text: "OpenAI",
                fontStyle: "headingMd",
                margin: "0 0 20px 0",
              }}
            />
            {!showApiKey && (
              <Text
                data={{
                  text: "Upgrade to a paid Frontly subscription to add your own OpenAI Key and use the GPT-4o model",
                  fontStyle: "headingSm",
                  color: colors.primary,
                  margin: "0 0 0 0",
                  onClick: () => navigate("/billing"),
                }}
              />
            )}
            {showApiKey && (
              <AdminForm
                labelStyle="headingSm"
                sectionPadding="5px"
                fields={aiFields
                  .filter((f) => !f.displayCondition || f.displayCondition())
                  .map((f) => ({
                    ...f,
                    componentId: f.componentId || "Input",
                    label: get(f, "label", startCase(f.id)),
                    value: get(app, f.id),
                  }))}
                submitText={"Save Changes"}
                submit={aiChanges ? () => save("ai") : null}
                width="220px"
                onChange={(k, v) => {
                  setChanges((c) => ({ ...c, [k]: v }));
                  setApp({
                    [k]: v,
                  });
                  setAIChanges(true);
                }}
              />
            )}
          </CardSection>

          <CardSection>
            <Text
              data={{
                text: "Mailgun",
                fontStyle: "headingMd",
                margin: "0 0 20px 0",
              }}
            />
            <AdminForm
              labelStyle="headingSm"
              sectionPadding="5px"
              fields={mailgunFields
                .filter((f) => !f.displayCondition || f.displayCondition())
                .map((f) => ({
                  ...f,
                  componentId: f.componentId || "Input",
                  label: get(f, "label", startCase(f.id)),
                  value: get(app, f.id),
                }))}
              submitText={"Save Changes"}
              submit={mailgunChanges ? () => save("mailgun") : null}
              width="220px"
              onChange={(k, v) => {
                setChanges((c) => ({ ...c, [k]: v }));
                setApp({
                  [k]: v,
                });
                setMailgunChanges(true);
              }}
            />
          </CardSection>

          <CardSection>
            <Text
              data={{
                text: "Make",
                fontStyle: "headingMd",
                margin: "0 0 20px 0",
              }}
            />
            <AdminForm
              labelStyle="headingSm"
              sectionPadding="5px"
              fields={makeFields
                .filter((f) => !f.displayCondition || f.displayCondition())
                .map((f) => ({
                  ...f,
                  componentId: f.componentId || "Input",
                  label: get(f, "label", startCase(f.id)),
                  value: get(app, f.id),
                }))}
              submitText={"Save Changes"}
              submit={makeChanges ? () => save("make") : null}
              width="220px"
              onChange={(k, v) => {
                setChanges((c) => ({ ...c, [k]: v }));
                setApp({
                  [k]: v,
                });
                setMakeChanges(true);
              }}
            />
          </CardSection>

          <CardSection>
            <Text
              data={{
                text: "Cloudinary",
                fontStyle: "headingMd",
                margin: "0 0 20px 0",
              }}
            />
            <AdminForm
              labelStyle="headingSm"
              sectionPadding="5px"
              fields={cloudinaryFields
                .filter((f) => !f.displayCondition || f.displayCondition())
                .map((f) => ({
                  ...f,
                  componentId: f.componentId || "Input",
                  label: get(f, "label", startCase(f.id)),
                  value: get(app, f.id),
                }))}
              submitText={"Save Changes"}
              submit={cloudinaryChanges ? () => save("cloudinary") : null}
              width="220px"
              onChange={(k, v) => {
                setChanges((c) => ({ ...c, [k]: v }));
                setApp({
                  [k]: v,
                });
                setCloudinaryChanges(true);
              }}
            />
          </CardSection>

          <CardSection>
            <Text
              data={{
                text: "Actions",
                fontStyle: "headingMd",
                margin: "0 0 20px 0",
              }}
            />
            <AdminForm
              labelStyle="headingSm"
              sectionPadding="5px"
              fields={actionFields
                .filter((f) => !f.displayCondition || f.displayCondition())
                .map((f) => ({
                  ...f,
                  componentId: f.componentId || "Input",
                  label: get(f, "label", startCase(f.id)),
                  value: get(app, f.id),
                }))}
              submitText={"Save Changes"}
              submit={actionChanges ? () => save("action") : null}
              width="220px"
              onChange={(k, v) => {
                setChanges((c) => ({ ...c, [k]: v }));
                setApp({
                  [k]: v,
                });
                setActionChanges(true);
              }}
            />
          </CardSection>
        </Column>
      </Row>
    </div>
  );
};

export default Integrations;
