import { get, isEmpty, startCase } from "lodash";
import {
  getInputBackgroundColor,
  getPixels,
  isFrontlyAdmin,
  passesCondition,
  safeArray,
  safeString,
} from "app/utils/utils";
import {
  rActiveEditField,
  rApp,
  rAppDateFormat,
  rDarkMode,
  rFetchingBlockIds,
  rFormState,
  rSavedSpreadsheets,
  rSpinner,
  rTranslations,
  spreadsheetsSelector,
} from "app/utils/recoil";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import useValidateFields, { getValidationObject } from "app/utils/validation";

import { Button } from "app/components";
import FormField from "app/renderingApp/blocks/Form/FormField";
import { InfoBox } from "app/adminApp/settings/InfoBox";
import { colors } from "app/utils/theme";
import { sortRecords } from "../Table";
import styled from "styled-components";
import { successNotification } from "app/utils/Notification";
import useActionResolver from "app/renderingApp/useActionResolver";
import useDynamicText from "app/renderingApp/useDynamicText";
import useSpreadsheetRequests from "app/useSpreadsheetRequests";
import useUtils from "app/renderingApp/useUtils";

export const prettyErrorMessage = (key, msg) => {
  return msg.replace(key, startCase(key));
};

export const sizeObject = {
  medium: "bodyMd",
  large: "bodyLg",
};

export const paddingObject = {
  medium: "10px",
  large: "15px",
};

export const labelStyleObject = {
  medium: "bodySm",
  large: "bodyMd",
};

export const gridRowGapObject = {
  medium: "16px",
  large: "18px",
};

export const gridColumnGapObject = {
  medium: "26px",
  large: "32px",
};

// This function handles finding the related dropdown fields which are formatted on the back-end
const getFormDataWithRelatedFields = (data) => {
  const { block, app, spreadsheets, formData } = data;

  let newFormData = { ...formData };

  safeArray(app, "data_relations")
    .filter((r) => r.sheet2 === get(block, "spreadsheet"))
    .forEach((r) => {
      const concatId = `${block.id}-${r.id}`;

      const m = isFrontlyAdmin
        ? []
        : get(spreadsheets, ["relations", concatId], []);

      const match = m.find(
        (i) => get(i, r.column1) === get(formData, r.column2)
      );

      if (match) {
        Object.keys(match).forEach((k) => {
          if (!["frontly_id", "frontly_data"].includes(k)) {
            newFormData[`${r.column2}__${k}`] = match[k];
          }
        });
      }
    });

  return newFormData;
};

export const getRelationColumnsFromBlock = (data) => {
  const { block, app, spreadsheets } = data;

  let relationMap = {};

  let relationColumns = {};
  safeArray(app, "data_relations")
    .filter((r) => r.sheet2 === get(block, "spreadsheet"))
    .forEach((r) => {
      const concatId = `${block.id}-${r.id}`;

      relationMap[r.sheet2] = r;

      const m = isFrontlyAdmin
        ? []
        : get(spreadsheets, ["relations", concatId], []);

      relationColumns[r.column2] = m;
    });

  return { relationColumns, relationMap };
};

export const getFormFields = (data) => {
  const {
    dataSourceId,
    relationMap,
    processDynamicText,
    appDateFormat = {},
    relatedHeaders = [],
    relationColumns = {},
    sheetHeaders = [],
    sheetFieldData = {},
    localFieldData = {},
    valuesObject = {},
    sheetOrder = [],
    localOrder,
    conditionsObject = null,
    showInactive = false,
    block = {},
    fieldDefault = { componentId: "Input" },
  } = data;

  // GET FINAL ORDER
  let order = [];
  if (safeArray(localOrder).length > 0) {
    order = localOrder;
  } else if (safeArray(sheetOrder).length > 0) {
    order = sheetOrder;
  }

  // Combine relation headers and regular headers
  let combinedHeaders = [...sheetHeaders, ...relatedHeaders];

  const sortedHeaders = [
    ...order.filter((o) => combinedHeaders.includes(o)),
    ...combinedHeaders.filter((h) => !order.includes(h)),
  ].filter((h) => !h.includes("frontly_id"));

  let fields = sortedHeaders
    .map((h) => {
      const sheetDateFormat = get(sheetFieldData, [h, "dateFormat"], {});
      const localDateFormat = get(localFieldData, [h, "dateFormat"], {});
      const mergedDateFormat = {
        ...appDateFormat,
        ...sheetDateFormat,
        ...localDateFormat,
      };

      let fieldObj = {
        key: h,
        label: startCase(h),
        active: true,
        ...fieldDefault,
        type: "text",
        value: get(valuesObject, h),
        ...get(sheetFieldData, h, {}),
        ...get(localFieldData, h, {}),
        dateFormat: mergedDateFormat,
      };

      // Handle sorting select dropdown items
      let options = get(fieldObj, "options", []).map((o) => ({
        ...o,
        value: processDynamicText({
          text: o.value,
          reusableBlockId: block.reusableBlockId,
          context: {
            form: valuesObject,
            repeatingRecord: get(block, "repeatingRecord"),
          },
        }),
      }));

      const selectSorting = get(fieldObj, "selectSorting");
      if (selectSorting) {
        options = sortRecords(options, "value", selectSorting);
        fieldObj["options"] = options;
      }

      return fieldObj;
    })
    .filter((f) => showInactive || f.active)
    // Ensure all fields have an id and key
    .map((f) => ({
      ...f,
      id: get(f, "id") || get(f, "key"),
      key: get(f, "key") || get(f, "id"),
    }))
    .map((f) => {
      if (f.componentId !== "Hidden") {
        let relationColumn = get(relationColumns, f.id);
        if (relationColumn) {
          // If field has hidden filters, filter the relation column
          // This is only relevant / enabled for 'related' fields
          const hiddenFilters = safeArray(f, "hiddenFilters");
          if (hiddenFilters.length > 0) {
            const passesFilters = (r) => {
              let pass = true;

              // Hidden Filters (FOR DETAIL VIEW CHILDREN ONLY)
              hiddenFilters.forEach((hf) => {
                let hfKey = hf.key;

                // Update may 8 - data is indeed processed on the back-end but
                // for that reason, the reference does NOT need to be adjust because it's already stitched
                // Since we process the data on the back-end now, this relation reference needs to be adjusted here
                // if (hfKey.includes("__")) {
                //   const parts = hfKey.split("__");
                //   if (parts.length === 2) {
                //     hfKey = get(parts, 1);
                //   }
                // }

                const val1 = get(r, hfKey);

                const val2 = processDynamicText({
                  text: hf.value,
                  reusableBlockId: block.reusableBlockId,
                  context: {
                    form: valuesObject,
                    repeatingRecord: get(block, "repeatingRecord"),
                  },
                });
                const operator = get(hf, "operator", "equals");

                const isValid =
                  val2 ||
                  ["exists", "does_not_exist", "is_true", "is_false"].includes(
                    operator
                  );

                if (
                  isValid &&
                  !passesCondition({ value1: val1, value2: val2, operator })
                ) {
                  pass = false;
                }
              });

              return pass;
            };

            relationColumn = relationColumn.filter((item) =>
              passesFilters(item)
            );
          }

          // Support multi-select if set that way for comma-separated relations
          let componentId =
            f.componentId === "MultiSelect" ? "MultiSelect" : "Select";

          // Handle supabase autocomplete
          const isDataBase =
            dataSourceId && safeString(dataSourceId).includes("db__");
          if (isDataBase) {
            const matchingRelation = get(relationMap, dataSourceId);
            return {
              ...f,
              componentId: "Autocomplete",
              matchingRelation,
            };
          }

          return { ...f, componentId, options: relationColumn };
        }
      }

      return f;
    });

  // Run display conditions if passed in
  if (conditionsObject) {
    const { passesDisplayConditions, conditionKey } = conditionsObject;
    fields = fields.filter((f) => {
      const fieldConditions = get(f, conditionKey, []);
      return passesDisplayConditions({
        conditions: fieldConditions,
        context: {
          spreadsheetColumn: valuesObject,
          form: valuesObject,
        },
      });
    });
  }

  const displayFields = fields.filter(
    (f) => showInactive || f.componentId !== "Hidden"
  );
  const hiddenFields = fields.filter((f) => f.componentId === "Hidden");

  return { displayFields, hiddenFields, displayOrder: sortedHeaders };
};

const Form = ({ page, block }) => {
  const [formErrors, setFormErrors] = useState({});

  const appDateFormat = useRecoilValue(rAppDateFormat);

  const darkMode = useRecoilValue(rDarkMode);
  const translations = useRecoilValue(rTranslations);

  const app = useRecoilValue(rApp);
  const savedSpreadsheets = useRecoilValue(rSavedSpreadsheets);

  const setActiveEditField = useSetRecoilState(rActiveEditField);

  const [spreadsheets, setSpreadsheets] = useRecoilState(spreadsheetsSelector);

  const fetchingBlockIds = useRecoilValue(rFetchingBlockIds);

  const spinner = useRecoilValue(rSpinner);
  const isFetching = !spinner && fetchingBlockIds.includes(get(block, "id"));

  const { passesDisplayConditions } = useUtils();

  const { processDynamicText } = useDynamicText();

  const { validateFields } = useValidateFields();

  const [hasSubmitted, setHasSubmitted] = useState(false);

  const sheet = savedSpreadsheets.find((s) => s.id === block.spreadsheet);

  // Spreadsheet-level field overrides
  const sheetFieldData = get(sheet, "field_data", {});
  const sheetHeaders = get(sheet, "headers", []);
  const sheetOrder = get(sheetFieldData, "order", []);

  // Block-level field overrides
  const fieldData = get(block, "fieldData", {});
  const config = get(fieldData, "config", {});
  const localOrder = get(fieldData, "order", []);

  const mode = get(block, "mode", "create");

  let formState = get(spreadsheets, block.id, {});
  // I THINK I FIXED THIS BUT I AM NOT READY TO DEAL WITH TESTING REMOVING IT
  if (get(formState, 0)) {
    formState = formState[0];
  }

  const [recoilFormState, setRecoilFormState] = useRecoilState(rFormState);

  // EDIT MODE DATA POPULATION
  useEffect(() => {
    // The current state of the connected spreadsheet
    if (mode === "edit" && !block.isFetching) {
      const defaultValues = getDefaultValuesObject();

      //Combine the default values with the form state, but only overwrite the value of formState with the default value if it's empty string, null or undefined
      if (!isEmpty(formState)) {
        let combined = { ...formState };

        if (get(block, "useDefaultsInEditMode")) {
          Object.keys(formState).forEach((k) => {
            const v = formState[k];
            if (v === "" || v === null || v === undefined) {
              combined[k] = defaultValues[k];
            }
          });
        }

        setFormValues(combined);

        // Update in recoil too
        setRecoilFormState({
          ...recoilFormState,
          ...combined,
        });
      }
    }
  }, [formState, block.isFetching, block.version_id]);

  const getDefaultValuesObject = () => {
    let defaultValuesObject = {};
    sheetHeaders.forEach((h) => {
      const m = get(config, h);
      if (["Switch", "Checkbox"].includes(get(m, "componentId"))) {
        defaultValuesObject[h] = get(m, "defaultValue") || false;
      }
      if (get(m, "active") !== false && get(m, "defaultValue")) {
        defaultValuesObject[h] = processDynamicText({
          text: get(m, "defaultValue"),
          reusableBlockId: block.reusableBlockId,
          context: { repeatingRecord: get(block, "repeatingRecord") },
        });
      }
    });
    return defaultValuesObject;
  };

  // CREATE MODE DATA POPULATION
  useEffect(() => {
    if (mode === "create" && !block.isFetching) {
      const defaultValues = getDefaultValuesObject();
      setFormValues(defaultValues);
      // Update in recoil too
      setRecoilFormState({
        ...recoilFormState,
        ...defaultValues,
      });
    }
  }, [page.id, block.isFetching, block.version_id]);

  // The local state managed here in this form
  const [formValues, setFormValues] = useState({});

  const clearForm = () => {
    setFormValues({});

    const clearedObject = Object.keys(formValues).reduce((acc, key) => {
      acc[key] = "";
      return acc;
    }, {});

    // Update in recoil too
    setRecoilFormState({
      ...recoilFormState,
      ...clearedObject,
    });

    setSpreadsheets({
      ...spreadsheets,
      [block.id]: {},
    });
    setHasSubmitted(false);
  };

  // RELATION LOGIC ----------------------------------------
  const { relationColumns, relationMap } = getRelationColumnsFromBlock({
    block,
    app,
    spreadsheets,
  });

  const formDataWithRelatedFields = getFormDataWithRelatedFields({
    block,
    app,
    spreadsheets,
    formData: formValues,
  });

  // RELATION LOGIC --------------------------------------------------

  const { displayFields, hiddenFields } = getFormFields({
    relationMap,
    dataSourceId: get(sheet, "id"),
    processDynamicText,
    appDateFormat,
    relationColumns,
    localOrder,
    sheetOrder,
    sheetHeaders,
    block,
    sheetFieldData: get(sheetFieldData, "config"),
    localFieldData: config,
    valuesObject: formValues,
    conditionsObject: {
      passesDisplayConditions,
      conditionKey: "conditions",
    },
  });

  const validationObject = getValidationObject(displayFields);

  const rowId = get(block, "rowId");

  const disableDefaultAction = get(block, "disableDefaultAction", false);

  const gridLayout = get(block, "gridLayout");

  const { updateRecord, createRecord } = useSpreadsheetRequests();

  const [isSaving, setIsSaving] = useState(false);

  const { actionResolver } = useActionResolver(page);

  const getSubmitBtnText = () => {
    const submitBtnText = get(block, "submitText");
    if (submitBtnText) {
      return submitBtnText;
    } else {
      if (mode === "create") {
        return "Save New Record";
      } else {
        return "Save Changes";
      }
    }
  };

  const createRow = (submitFunc) => {
    setIsSaving(true);

    // Get changed values
    let updateValues = [];

    Object.keys(formValues).forEach((k) => {
      const v = get(formValues, k, "");
      if (!safeString(v).trim().startsWith("=")) {
        updateValues.push({
          key: k,
          value: processDynamicText({
            text: v,
            reusableBlockId: block.reusableBlockId,
            context: { repeatingRecord: get(block, "repeatingRecord") },
          }),
        });
      }
    });

    hiddenFields.forEach((f) => {
      const hiddenValue = get(f, "hiddenValue");
      if (hiddenValue) {
        updateValues.push({
          key: f.key,
          value: processDynamicText({
            text: hiddenValue,
            reusableBlockId: block.reusableBlockId,
            context: { repeatingRecord: get(block, "repeatingRecord") },
          }),
        });
      }
    });

    createRecord({
      sheetId: block.spreadsheet,
      values: updateValues,
    }).then((newRecord) => {
      setIsSaving(false);
      successNotification(get(translations, "recordCreated", "Record Created"));
      submitFunc(newRecord);
    });
  };

  const update = (submitFunc) => {
    setIsSaving(true);

    const disabledFieldKeys = Object.keys(config).filter((k) => {
      const isDisabled = get(config, [k, "active"]) === false;
      return isDisabled;
    });

    // Get changed values
    let updateValues = [];
    Object.keys(formValues)
      .filter((k) => !["frontly_id", "frontly_data"].includes(k))
      .forEach((k) => {
        const v = get(formValues, k, "");
        if (!safeString(v).trim().startsWith("=")) {
          updateValues.push({ key: k, value: v });
        }
      });

    hiddenFields.forEach((f) => {
      const hiddenValue = get(f, "hiddenValue");
      if (hiddenValue) {
        updateValues.push({
          key: f.key,
          value: processDynamicText({
            text: hiddenValue,
            reusableBlockId: block.reusableBlockId,
            context: { repeatingRecord: get(block, "repeatingRecord") },
          }),
        });
      }
    });

    // One last final filter to make sure inactive fields don't send data
    updateValues = updateValues.filter((v) => {
      if (v.key.includes("__")) {
        return false;
      }

      return !disabledFieldKeys.includes(v.key);
    });

    updateRecord({
      sheetId: block.spreadsheet,
      rowIdColumn: block.rowIdColumn,
      recordId: rowId,
      values: updateValues,
    }).then(() => {
      setIsSaving(false);
      successNotification(get(translations, "savedNotification", "Saved"));
      submitFunc({});
    });
  };

  const onSubmitClick = async () => {
    if (isFrontlyAdmin) {
      return null;
    }

    setHasSubmitted(true);

    if (
      isEmpty(validateFields(validationObject, formValues, true, setFormErrors))
    ) {
      const submitFunc = (newRecord) =>
        actionResolver({
          rawAction: get(block, ["actionMap", "submitAction"]),
          actionId: get(block, "submitAction"),
          context: {
            form: {
              ...formValues,
              ...formDataWithRelatedFields,
              ...newRecord,
            },
          },
          blockId: block.id,
        });

      if (!disableDefaultAction) {
        if (mode === "create") {
          createRow(submitFunc);
        }
        if (mode === "edit") {
          update(submitFunc);
        }
      } else {
        submitFunc(formValues);
      }

      // Clear form if turned on
      if (get(block, "clearOnSubmit")) {
        clearForm();
      }
    }
  };

  const runValidation = (fieldKey, newValues) => {
    // Ensure the field is in the validation object
    if (
      !isEmpty(newValues) &&
      !isEmpty(validationObject) &&
      get(validationObject, fieldKey)
    ) {
      validateFields(validationObject, newValues, hasSubmitted, setFormErrors);
    }
  };

  const incomplete = mode === "edit" && !rowId;
  if (incomplete) {
    return (
      <InfoBox
        darkMode={darkMode}
        warning
        margin="15px 5px 5px 5px"
        helpLink="https://help.frontly.ai/en/articles/7971088-form-block"
      >
        To complete Form setup, please add a 'Row ID' or change the 'Mode' to
        Create.
      </InfoBox>
    );
  }

  const maxContentWidth = get(block, "maxWidth");

  const inputSize = get(app, ["styling", "formInputSize"], "medium");
  const inputRadius = get(app, ["styling", "formInputRadius"]);
  const size = get(sizeObject, inputSize);
  const padding = get(paddingObject, inputSize);
  const labelStyle = get(labelStyleObject, inputSize);
  const gridRowGap = get(gridRowGapObject, inputSize);
  const gridColumnGap = get(gridColumnGapObject, inputSize);
  const formInputBorderColor = getPixels(
    get(app, ["styling", "formInputBorderColor"])
  );

  const buttonBorderRadius = get(app, ["styling", "buttonBorderRadius"]);
  // Min Grid Size
  // let minGridSize = maxContentWidth < 300 ? maxContentWidth - 60 : 200;

  let minGridSize = 250;

  const resolvedRowId = processDynamicText({
    text: rowId,
    reusableBlockId: block.reusableBlockId,
    context: {
      repeatingRecord: get(block, "repeatingRecord"),
    },
  });

  return (
    <div>
      <FieldsContainer
        minGridSize={minGridSize}
        gridLayout={gridLayout}
        count={displayFields.length}
        gridRowGap={getPixels(gridRowGap)}
        gridColumnGap={getPixels(gridColumnGap)}
      >
        {displayFields.map((field, i) => {
          // GET THE VALUE FOR THE FIELD
          const value = get(formValues, field.key) || "";

          let componentId = field.componentId;
          if (
            field.disabled &&
            ["DateTimePicker", "FileUpload"].includes(componentId)
          ) {
            componentId = "Input";
          }

          // Adding this (particularly the row ID) should force a re-render when the row ID changes
          const uniqueFormFieldKey = `${page.id}-${block.id}-${field.key}-${resolvedRowId}`;

          return (
            <FormField
              key={uniqueFormFieldKey}
              data={{
                ...field,
                required: get(validationObject, [field.key, "required"], false),
                skeleton: block.isFetching,
                fontStyle: size,
                labelStyle,
                padding,
                darkMode,
                labelColor: darkMode && colors.darkModeLightText2,
                background: getInputBackgroundColor({
                  darkMode,
                  disabled: field.disabled,
                }),
                borderRadius: getPixels(inputRadius),
                border: darkMode
                  ? `1px solid ${colors.darkModeLightGrey}`
                  : formInputBorderColor
                  ? `1px solid ${formInputBorderColor}`
                  : null,
                color: darkMode && "white",
                borderRadius: getPixels(inputRadius),
                borderColor: formInputBorderColor,
                value,
                componentId,
                handleFieldClick: (obj) =>
                  setActiveEditField({ ...obj, blockId: block.id }),
                width: "100%",
                onChange: (value) => {
                  const newValues = {
                    ...formValues,
                    [field.key]: value,
                  };

                  setFormValues(newValues);

                  // Update in recoil too
                  setRecoilFormState({
                    ...recoilFormState,
                    ...newValues,
                  });

                  runValidation(field.key, newValues);
                },
                error: get(formErrors, field.key),
              }}
            />
          );
        })}
      </FieldsContainer>
      {!get(block, "hideSubmitButton") && (
        <Button
          data={{
            onClick: onSubmitClick,
            disabled: isFetching,
            text: getSubmitBtnText(),
            isFetching: isSaving,
            backgroundColor: get(app, "primary_color"),
            borderRadius: buttonBorderRadius
              ? getPixels(buttonBorderRadius)
              : null,
          }}
        />
      )}
    </div>
  );
};

export default Form;

const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-row-gap: ${(p) => p.gridRowGap};
  grid-column-gap: ${(p) => p.gridColumnGap};
  margin: 0 0 20px 0;
  ${(p) =>
    p.gridLayout &&
    `
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(${getPixels(
      p.minGridSize
    )}, 1fr));
  `}
`;
