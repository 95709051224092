import { apiRequest, webUrl } from "app/utils/apiRequests";

import { Button } from "app/components";
import { get } from "lodash";
import { getUrlParameter } from "app/utils/utils";
import pkceChallenge from "pkce-challenge";
import { rApp } from "app/utils/recoil";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";

const AirtableAuth = () => {
  const [app, setApp] = useRecoilState(rApp);

  const clientId = process.env.REACT_APP_AIRTABLE_CLIENT_ID;
  const redirectUri = `${webUrl}/spreadsheets`;

  const location = useLocation();

  const handleAirtableAuth = async () => {
    const { code_verifier, code_challenge } = await pkceChallenge();
    localStorage.setItem("code_verifier", code_verifier);

    // TODO - Generate this?
    const mockState = "1234123412341abcd";

    const authUrl = `https://airtable.com/oauth2/v1/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&state=${mockState}&scope=schema.bases:read data.records:read data.records:write&response_type=code&code_challenge=${code_challenge}&code_challenge_method=S256`;

    window.location.href = authUrl;
  };

  const handleAirtableDisconnect = async () => {
    // Disconnect from Airtable
    apiRequest.post("/app_settings/", {
      airtable_token: null,
    });
    setApp({
      ...app,
      airtable_token: null,
    });
  };

  useEffect(() => {
    const code = getUrlParameter("code", location);

    if (code) {
      const code_verifier = localStorage.getItem("code_verifier");

      apiRequest
        .post("/airtable_auth/", { code, code_verifier })
        .then((response) => {
          // Handle successful response
          // console.log("Airtable Connected");
          setApp({
            ...app,
            airtable_token: true,
          });
        })
        .catch((error) => {
          // Handle error
          console.error("Error exchanging code:", error);
        });
    }
  }, [location]);

  const airtableToken = get(app, "airtable_token");

  return (
    <Button
      data={{
        text: airtableToken
          ? "Airtable Connected - Click To Disconnect"
          : "Connect to Airtable",
        onClick: airtableToken ? handleAirtableDisconnect : handleAirtableAuth,
        type: "basic",
        margin: "20px 0 0 0",
      }}
    />
  );
};

export default AirtableAuth;
