import { Column, Text } from "app/components";
import { rApp, rOrganization, rUser } from "app/utils/recoil";
import { useRecoilState, useRecoilValue } from "recoil";

import AdminForm from "../components/AdminForm";
import { CardSection } from "./SettingsSection";
import { apiRequest } from "app/utils/apiRequests";
import { colors } from "app/utils/theme";
import { get } from "lodash";
import { successNotification } from "app/utils/Notification";
import { useState } from "react";

const Advanced = () => {
  const [app, setApp] = useRecoilState(rApp);

  const organization = useRecoilValue(rOrganization);
  const user = useRecoilValue(rUser);

  const [changes, setChanges] = useState({});

  const save = (sectionKey) => {
    successNotification("Saved");

    const sectionChanges = get(changes, sectionKey);
    apiRequest.post("/app_settings/", sectionChanges);

    setChanges({
      ...changes,
      [sectionKey]: null,
    });
  };

  const allowCustomJavascript = get(organization, "allow_custom_javascript");

  const appInstallLink = `https://app.frontly.ai/apps?install_app_id=${app.subdomain}`;

  const appSharingFields = [
    {
      id: "authorized_install_emails",
      label: "Authorized Install Emails",
      hint: "Allow specific users to install a copy of your app by entering their emails here in a comma-separated list",
      componentId: "Input",
      value: get(app, "authorized_install_emails"),
      displayCondition: () => user.role === "owner",
    },
    {
      id: "app_install_link",
      label: "App Install Link",
      componentId: "Text",
      hint: "Share this link with users to install a copy of your app. This will only work for users who have been added to the authorized install emails. Click to copy the link.",
      text: appInstallLink,
      fontColor: colors.primary,
      fontWeight: "500",
      onClick: () => {
        navigator.clipboard.writeText(appInstallLink);
        successNotification("Link copied to clipboard");
      },
      displayCondition: () =>
        user.role === "owner" && get(app, "authorized_install_emails"),
    },
  ];

  const fields = [
    {
      id: "enable_block_versions",
      label: "Enable Block Versions",
      hint: "Enable the 'block versions' feature which allows conditional settings for a specific block.",
      componentId: "Switch",
      value: get(app, "enable_block_versions"),
    },
    {
      id: "use_mock_values",
      label: "Use Mock Values",
      hint: "This setting prevents Frontly from storing any of your data source rows in the database, and instead shows generic data in the admin mode. Note that this setting will not affect the user-facing app.",
      componentId: "Switch",
      value: get(app, "use_mock_values"),
    },
    {
      id: "disable_admin_animations",
      label: "Increase Admin Performance",
      hint: "Disable animations in the admin panel and reduce the number of displayed rows to increase performance. This will not affect the user-facing app. This is only useful if you have a lot of data or blocks, or are experiencing unresponsive behavior in the admin panel.",
      componentId: "Switch",
      value: get(app, "disable_admin_animations"),
    },
    {
      id: "include_full_relation_records",
      label: "Include Full Relation Records",
      hint: "This is an advanced setting which is only rarely needed. If you are not sure, leave it off. By default, when data relations are loaded, only the minimal data is used.",
      componentId: "Switch",
      value: get(app, "include_full_relation_records"),
    },
    {
      id: "show_active_block_delete_button",
      label: "Show Active Block Delete Button",
      hint: "Show a delete button on the active block in the admin panel instead of the collapse sidebar button.",
      componentId: "Switch",
      value: get(app, "show_active_block_delete_button"),
    },
    {
      id: "number_format",
      label: "Number Format",
      componentId: "Select",
      value: get(app, "number_format"),
      options: [
        {
          label: "Euro",
          value: "euro",
        },
      ],
    },
    {
      id: "custom_javascript",
      label: "Custom Javascript",
      hint: "Add custom Javascript to your app. This will be added to the head of your app. Enter valid javascript functions only",
      componentId: "TextArea",
      orientation: "vertical",
      value: get(app, "custom_javascript"),
      displayCondition: () => allowCustomJavascript,
    },
  ];

  const apiFields = [
    {
      id: "allow_create_users_webhook",
      label: "Allow Create Users Webhook",
      hint: "Enable a webhook that allows users to be created via API - This is an advanced feature",
      componentId: "Switch",
      value: get(app, "allow_create_users_webhook"),
    },
    {
      id: "api_key",
      label: "API Key",
      hint: "For authenticating requests from third-party services",
      componentId: "Input",
      disabled: true,
      value: get(organization, "api_key"),
    },
  ];

  const sections = [
    {
      key: "appSharing",
      title: "App Sharing",
      subtitle:
        "Allow other users to install a copy of your entire app and data.",
      fields: appSharingFields,
    },
    {
      key: "api",
      title: "API Endpoints",
      subtitle: "Create users via API through a webhook",
      fields: apiFields,
    },
    {
      key: "other",
      title: "Other",
      subtitle: "Other assorted settings",
      fields: fields,
    },
  ];

  return (
    <>
      <Column style={{ width: "600px" }} gap="30px">
        <div>
          <Text
            data={{
              text: "Advanced",
              fontStyle: "headingLg",
              margin: "0 0 10px 0",
            }}
          />
          <Text
            data={{
              text: "Configure advanced settings for your app",
              fontStyle: "bodyMd",
              color: colors.grey3,
            }}
          />
        </div>

        {sections.map((section, i) => {
          const sectionChanges = get(changes, section.key);
          return (
            <CardSection>
              <Text
                data={{
                  text: section.title,
                  fontStyle: "headingMd",
                }}
              />
              <Text
                data={{
                  text: section.subtitle,
                  fontStyle: "bodyMd",
                  margin: "5px 0 20px 0",
                }}
              />
              <AdminForm
                labelStyle="headingSm"
                sectionPadding="0px"
                fields={section.fields.filter(
                  (f) =>
                    !f.displayCondition ||
                    (f.displayCondition && f.displayCondition(f))
                )}
                submitText={"Save Changes"}
                submit={sectionChanges ? () => save(section.key) : null}
                onChange={(k, v) => {
                  setChanges({
                    ...changes,
                    [section.key]: {
                      ...get(changes, section.key),
                      [k]: v,
                    },
                  });
                  setApp({
                    [k]: v,
                  });
                }}
              />
            </CardSection>
          );
        })}
      </Column>
    </>
  );
};

export default Advanced;
