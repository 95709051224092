import "react-notifications-component/dist/theme.css";

import {
  Button,
  ConfirmationModal,
  FullScreenLoader,
  Icon,
  Modal,
  Row,
  Text,
} from "./components";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import {
  filterBasedOnUserGroups,
  findDefaultPage,
  getUrlParameter,
  isFrontlyAdmin,
  publicRoutes,
} from "./utils/utils";
import { get, isEmpty } from "lodash";
import {
  rAdminPreview,
  rApp,
  rApps,
  rForms,
  rIsFetchingConfig,
  rIsIntercomBooted,
  rIsMixpanelInitialized,
  rOrganization,
  rPages,
  rUser,
} from "./utils/recoil";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import AdminLogin from "./adminApp/pages/AdminLogin";
import AiGeneration from "./adminApp/generate/AiGeneration/AiGeneration";
import AiView from "./adminApp/aiview";
import AppSumoSignup from "./adminApp/pages/AppSumoSignup";
import AppTemplates from "./adminApp/landing/AppTemplates";
import Apps from "./adminApp/home/Apps";
import Assets from "./adminApp/assets";
import Billing from "./adminApp/billing";
import Cookies from "js-cookie";
import CustomerPasswordReset from "./adminApp/settings/CustomerPasswordReset";
import EditActiveUser from "./adminApp/settings/EditActiveUser";
import EditExpertProfile from "./adminApp/experts/expert/EditExpertProfile";
import ExpertProfile from "./adminApp/experts/client/ExpertProfile";
import Experts from "./adminApp/experts";
import ExpertsInternal from "./adminApp/experts/client/ExpertsInternal";
import FormEditor from "./adminApp/forms/FormEditor";
import Forms from "./adminApp/forms";
import FullScreenForm from "./renderingApp/blocks/FullScreenForm";
import FullScreenLoaderClient from "./components/FullScreenLoaderClient";
import Home from "./adminApp/home";
import InternalAdmin from "./adminApp/internalAdmin";
import InternalAdminExperts from "./adminApp/internalAdmin/InternalAdminExperts";
import Login from "./adminApp/pages/Login";
// import NewTemplates from "./adminApp/new_templates/NewTemplates";
import OnboardingSurvey from "./adminApp/onboarding/OnboardingSurvey";
import PageBuilder from "./adminApp/pageBuilder";
import Pages from "./adminApp/home/Pages";
import Planner from "./adminApp/generate/Planner";
import PrivateRoute from "./PrivateRoute";
import Profile from "./adminApp/experts/Profile";
import Project from "./adminApp/experts/client/Project";
import ProjectPlanner from "./adminApp/experts/client/ProjectPlanner";
import Projects from "./adminApp/experts/client/Projects";
import Radix from "./Radix";
import ReactGA from "react-ga4";
import { ReactNotifications } from "react-notifications-component";
import RenderPage from "./renderPage";
import ReportDetails from "./adminApp/reports/ReportDetails";
import ReportDetailsNew from "./adminApp/reports_2024/ReportDetails";
import Reports from "./adminApp/reports";
import ReportsNew from "./adminApp/reports_2024";
import Settings from "./adminApp/settings";
import Spreadsheets from "./adminApp/spreadsheets";
import TemplateLandingPage from "./adminApp/landing/TemplateLandingPage";
import Templates from "./adminApp/home/Templates";
import Users from "./adminApp/users";
import { badges } from "./utils/theme";
import mixpanel from "mixpanel-browser";
import { useBranding } from "./useBranding";
import useFetchAppConfig from "./utils/useFetchAppConfig";
import { useIntercom } from "react-use-intercom";
import useModalStateData from "./useModalStateData";
import useUtils from "./renderingApp/useUtils";
import useWebsocket from "./useWebsocket";

function App() {
  const user = useRecoilValue(rUser);
  const organization = useRecoilValue(rOrganization);
  const app = useRecoilValue(rApp);
  const savedForms = useRecoilValue(rForms);
  const savedPages = useRecoilValue(rPages);
  const apps = useRecoilValue(rApps);
  const isFetchingConfig = useRecoilValue(rIsFetchingConfig);

  const fetchAppConfig = useFetchAppConfig();

  const { boot } = useIntercom();

  const appCount = apps.length;

  const isExpert = get(organization, "is_expert");

  const [showUpgradePopup, setShowUpgradePopup] = useState(true);

  const { passesDisplayConditions } = useUtils();

  const location = useLocation();

  useBranding();
  useWebsocket();

  const forms = savedForms.filter(
    (f) =>
      f.published &&
      passesDisplayConditions({ conditions: get(f, "displayConditions", []) })
  );

  const publicForms = forms.filter((p) => !get(p, ["requireLogin"]));
  const privateForms = forms.filter((p) => get(p, ["requireLogin"]));

  const pages = savedPages
    .filter(
      (p) => p.published || ["owner", "admin"].includes(get(user, "role"))
    )
    .filter((p) =>
      passesDisplayConditions({ conditions: get(p, "displayConditions", []) })
    );

  const publicPages = pages.filter((p) => !get(p, ["requireLogin"]));

  const privatePages = filterBasedOnUserGroups({
    items: pages.filter((p) => get(p, ["requireLogin"])),
    app,
    user,
    passesDisplayConditions,
  });

  const defaultPageId = get(app, "default_page");

  const defaultForm = publicForms.find((f) => f.makeDefaultPage);

  const defaultPrivatePage = privatePages.find((p) => p.id === defaultPageId);

  const defaultPublicPage = publicPages.find((p) => p.id === defaultPageId);

  const setIsMixpanelInitialized = useSetRecoilState(rIsMixpanelInitialized);

  const [adminPreview, setAdminPreview] = useRecoilState(rAdminPreview);

  const [isIntercomBooted, setIsIntercomBooted] =
    useRecoilState(rIsIntercomBooted);

  const path = get(location, "pathname");

  const subscription = get(organization, "subscription");
  const currentPlan = get(subscription, "plan");
  const planPrice = get(subscription, "amount");

  const isPublicRoute =
    publicRoutes.includes(path) ||
    (isFrontlyAdmin && path.includes("/expert/")) ||
    (isFrontlyAdmin && path.includes("/app_templates/"));

  const showUpgrade =
    !path.includes("billing") &&
    !isPublicRoute &&
    showUpgradePopup &&
    !currentPlan &&
    isFrontlyAdmin &&
    !Cookies.get("hideUpgradePopup");

  const hideUpgradePopup = () => {
    setShowUpgradePopup(false);
    Cookies.set("hideUpgradePopup", true, { expires: 1 });
  };

  useEffect(() => {
    mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);
    setIsMixpanelInitialized(true);

    if (isFrontlyAdmin) {
      ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
      const via = getUrlParameter("via", location);
      if (via) {
        Cookies.set("via", via, { expires: 90 });
      }

      const ref = getUrlParameter("ref", location);
      if (ref) {
        Cookies.set("ref", ref, { expires: 90 });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const localhost = window.location.host.includes("localhost");

    if (
      adminPreview === false &&
      !localhost &&
      !isIntercomBooted &&
      user &&
      window.location.host.includes("app.frontly.ai")
    ) {
      setIsIntercomBooted(true);

      boot({
        userId: user.id,
        name: `${user.first_name} ${user.last_name}`,
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        customAttributes: {
          app_count: appCount,
          // plan stuff
          subscription_created_date: get(subscription, "created_date"),
          subscription_updated_date: get(subscription, "updated_date"),
          subscription_cancelled_date: get(subscription, "cancelled_date"),
          plan_name: currentPlan,
          plan_price: planPrice,
          //
          referral_source: organization.referral_source,
          organization: organization.id,
          is_expert: isExpert,
          ai_usage: get(organization, "ai_usage", 0),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get(user, "id")]);

  const navigate = useNavigate();

  const magicLink = getUrlParameter("magic", location);
  const magicRoute = magicLink && getUrlParameter("route", location);

  useEffect(() => {
    const adminPreviewParam = getUrlParameter("admin_preview", location);

    const adminPreviewCookie = Cookies.get("adminPreview");
    if (adminPreviewParam === "true" || adminPreviewCookie) {
      setAdminPreview(true);
      if (!adminPreviewCookie) {
        Cookies.set("adminPreview", true, { expires: 30 });
      }
    } else {
      setAdminPreview(false);
    }

    if (magicLink) {
      Cookies.set("accessToken", magicLink, { expires: 30 });
      if (magicRoute) {
        navigate(magicRoute);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle when you have magic link, but no specific route defined, send to first page
  useEffect(() => {
    if (user) {
      if (magicLink && magicRoute === "") {
        if (user.role === "user") {
          const fallback = get(savedPages, 0, {});
          const defaultPage =
            savedPages.find((p) => p.id === get(app, "default_page")) ||
            fallback;
          const userGroups = get(app, "user_groups");

          const defPage = findDefaultPage({
            user,
            userGroups,
            pages: savedPages,
            defaultPage,
            app,
            passesDisplayConditions,
          });

          navigate(defPage);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get(user, "id")]);

  const [customJsRun, setCustomJsRun] = useState(false);

  const { setModalStack, modalStack } = useModalStateData();

  // Fetch app config
  useEffect(() => {
    const r = fetchAppConfig({});

    // This ensures that the modal stack is cleared when you navigate to a new page
    if (modalStack.length > 0) {
      setModalStack([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  const getFinalRoute = (page) => {
    const route = get(page, "route");
    return route;
  };

  useEffect(() => {
    if (!isPublicRoute && isFrontlyAdmin && !Cookies.get("accessToken")) {
      navigate("/login");
    }
  }, []);

  const showSpinner =
    isFetchingConfig || ((!isPublicRoute || !isFrontlyAdmin) && isEmpty(app));

  const allowCustomJavascript = get(organization, "allow_custom_javascript");

  // JS
  useEffect(() => {
    if (
      allowCustomJavascript &&
      !showSpinner &&
      !isFrontlyAdmin &&
      !customJsRun &&
      !isEmpty(app)
    ) {
      setCustomJsRun(true);

      // Extract or get the JavaScript code from `activeApp`
      const customJs = get(app, "custom_javascript", "");

      if (customJs) {
        // Match all <script> tags, including those with src attributes
        const scriptMatches = customJs.matchAll(
          /<script.*?>[\s\S]*?<\/script>/gi
        );
        const scripts = [...scriptMatches];

        if (scripts.length > 0) {
          scripts.forEach((match) => {
            const scriptContent = match[0];
            const scriptElement = document.createElement("script");
            const scriptTagMatch = scriptContent.match(
              /<script.*?>([\s\S]*?)<\/script>/i
            );
            const scriptSrcMatch = scriptContent.match(/src="([^"]+)"/i);

            if (scriptSrcMatch && scriptSrcMatch[1].trim()) {
              // External JavaScript source
              // console.log("External script:", scriptSrcMatch[1]);
              scriptElement.src = scriptSrcMatch[1];
              if (scriptContent.includes("async")) scriptElement.async = true;
            } else if (scriptTagMatch && scriptTagMatch[1].trim()) {
              // Inline JavaScript code
              // console.log("Inline JS code");
              scriptElement.innerHTML = scriptTagMatch[1];
            } else {
              // This case is unlikely due to the regex match, but included for completeness
              // console.log("Plain JS or unmatched case", scriptContent);
              scriptElement.innerHTML = scriptContent;
            }

            scriptElement.type = "text/javascript";
            document.head.appendChild(scriptElement);
          });
        } else {
          // Handle case where there are no <script> tags, assuming plain JavaScript
          // console.log("Plain JavaScript without <script> tags", customJs);
          const scriptElement = document.createElement("script");
          scriptElement.innerHTML = customJs;
          scriptElement.type = "text/javascript";
          document.head.appendChild(scriptElement);
        }

        // Clean up script elements when the component unmounts
        return () => {
          document
            .querySelectorAll("script[data-custom-js='true']")
            .forEach((script) => {
              document.head.removeChild(script);
            });
        };
      }
    }
  }, [app]);

  if (showSpinner) {
    return <FullScreenLoader />;
  }

  const enableProfileEditing = get(app, "enable_edit_profile", false);

  const renderDefaults = () => {
    if (defaultForm) {
      return (
        <Route path={""} element={<FullScreenForm data={defaultForm} />} />
      );
    } else if (defaultPublicPage) {
      return (
        <Route
          path=""
          element={<RenderPage page={defaultPublicPage} isPublicRoute />}
        />
      );
    }

    return <Route path="" element={<Login type="login" />} />;
  };

  return (
    <div>
      {showUpgrade && <UpgradeModal hide={hideUpgradePopup} />}
      <div id="dropdown-portal-root" className="test"></div>
      <ReactNotifications style={{ zIndex: 2000 }} />
      <FullScreenLoaderClient />
      <AiView />
      <Routes>
        {/* <Route path="new_templates" element={<NewTemplates />} /> */}
        {isFrontlyAdmin && (
          <>
            {/* ADMIN ROUTES */}
            <Route
              path="internal_admin/experts"
              element={<InternalAdminExperts />}
            />
            <Route path="internal_admin" element={<InternalAdmin />} />
            {/* <Route path="setup" element={<Setup />} /> */}

            {/* EXPERTS */}
            <Route path="expert-profile" element={<EditExpertProfile />} />
            <Route path="experts" element={<Experts />} />
            <Route path="expert/:id" element={<Profile />} />
            <Route path="profile/:id" element={<ExpertProfile />} />
            <Route path="experts-marketplace" element={<ExpertsInternal />} />
            <Route path="projects" element={<Projects />} />
            <Route path="project/:id" element={<Project />} />

            <Route path="onboarding" element={<OnboardingSurvey />} />
            <Route path="project-planner/:id" element={<ProjectPlanner />} />
            <Route path="forms" element={<Forms />} />
            <Route path="form/:id" element={<FormEditor />} />
            <Route path="custom-block/:id" element={<PageBuilder />} />
            <Route path="page/:id" element={<PageBuilder />} />
            <Route path="pages" element={<Pages />} />
            <Route path="apps" element={<Apps />} />
            <Route path="home" element={<Home />} />
            <Route path="billing" element={<Billing />} />
            <Route path="spreadsheets" element={<Spreadsheets />} />
            <Route path="chat/:id" element={<AiGeneration />} />
            <Route path="internal_admin/chat/:id" element={<AiGeneration />} />
            <Route path="planner/:id" element={<Planner />} />
            <Route path="planner" element={<Planner />} />
            <Route path="report/:id" element={<ReportDetails />} />
            <Route path="reports" element={<Reports />} />
            <Route path="magicmetrics/:id" element={<ReportDetailsNew />} />
            <Route path="magicmetrics" element={<ReportsNew />} />
            <Route path="users" element={<Users />} />
            <Route path="settings" element={<Settings />} />
            <Route path="assets" element={<Assets />} />
            <Route path="appsumo_signup" element={<AppSumoSignup />} />
            <Route path="v1signup" element={<Login type="signup" />} />
            <Route path="" element={<AdminLogin />} />
            <Route path="login" element={<AdminLogin type="login" />} />
            <Route path="signup" element={<AdminLogin type="signup" />} />
            <Route path="app_templates/:id" element={<TemplateLandingPage />} />
            <Route
              path="app_templates"
              element={<AppTemplates type="app_templates" />}
            />
            <Route path="templates" element={<Templates />} />
          </>
        )}

        <Route path="fullscreenform" element={<FullScreenForm />} />
        <Route path="radix" element={<Radix />} />
        {!isFrontlyAdmin && (
          <>
            {renderDefaults()}

            <Route path="login" element={<Login type="login" />} />
            {get(app, ["authentication", "enableSignup"]) && (
              <Route path="signup" element={<Login type="signup" />} />
            )}

            {publicForms.map((f) => {
              return (
                <Route
                  key={get(f, "route")}
                  path={get(f, "route")}
                  element={<FullScreenForm data={f} />}
                />
              );
            })}

            {/* USER ROUTES */}
            {publicPages.map((page) => {
              let route = getFinalRoute(page);
              return (
                <Route
                  key={route}
                  path={route}
                  element={<RenderPage page={page} isPublicRoute />}
                />
              );
            })}
            <Route path="/" element={<PrivateRoute />}>
              <Route
                path="/reset_password"
                element={<CustomerPasswordReset />}
              />
              {enableProfileEditing && (
                <Route path="edit-profile" element={<EditActiveUser />} />
              )}
              {privateForms.map((f) => {
                return (
                  <Route
                    key={get(f, "route")}
                    path={get(f, "route")}
                    element={<FullScreenForm data={f} />}
                  />
                );
              })}
              {privatePages.map((page) => {
                let route = getFinalRoute(page);
                return (
                  <Route
                    key={route}
                    path={route}
                    element={<RenderPage page={page} />}
                  />
                );
              })}

              {/* IF THERE IS A DEFAULT PRIVATE PAGE, RENDER THAT */}
              {defaultPrivatePage && (
                <Route
                  path="*"
                  element={<RenderPage page={defaultPrivatePage} />}
                />
              )}
            </Route>
          </>
        )}

        {/* IF THERE IS A DEFAULT PUBLIC PAGE, RENDER THAT */}
        {!defaultPrivatePage && defaultPublicPage && (
          <Route
            path="*"
            element={<RenderPage page={defaultPublicPage} isPublicRoute />}
          />
        )}

        {/* IF NO DEFAULT PRIVATE OR PUBLIIC PAGES ACCESSIBLE, RENDER LOGIN */}
        {!defaultPrivatePage && !defaultPublicPage && (
          <Route path="*" element={<Login type="login" />} />
        )}
      </Routes>
      <ConfirmationModal />
    </div>
  );
}

export default App;

const UpgradeModal = ({ hide }) => {
  const navigate = useNavigate();

  return (
    <Modal
      header={{ title: "Claim Frontly's Lifetime Deal Now for $499" }}
      hide={hide}
    >
      <Text
        data={{
          fontStyle: "bodyLg",
          text: "Includes lifetime access to Frontly with:",
        }}
      />

      <Row alignItems="center" gap="10px" margin="20px 0 0 0">
        <Icon data={{ icon: "FiCheckCircle", color: badges.green, size: 20 }} />
        <Text data={{ fontStyle: "headingMd", text: "Unlimited Apps" }} />
      </Row>
      <Row alignItems="center" gap="10px" margin="10px 0 0 0">
        <Icon data={{ icon: "FiCheckCircle", color: badges.green, size: 20 }} />
        <Text
          data={{
            fontStyle: "headingMd",
            text: "Unlimited Users",
          }}
        />
      </Row>
      <Row alignItems="center" gap="10px" margin="10px 0 0 0">
        <Icon data={{ icon: "FiCheckCircle", color: badges.green, size: 20 }} />
        <Text
          data={{ fontStyle: "headingMd", text: "Custom Domain & Branding" }}
        />
      </Row>
      <Row alignItems="center" gap="10px" margin="10px 0 0 0">
        <Icon data={{ icon: "FiCheckCircle", color: badges.green, size: 20 }} />
        <Text
          data={{
            fontStyle: "headingMd",
            text: "500 Monthly AI Tokens (10,000 with your own OpenAI Key)",
          }}
        />
      </Row>

      <Button
        data={{
          text: "View Offer",
          onClick: () => {
            hide();
            navigate("/billing");
          },
          size: "large",
          margin: "20px 0 0 0",
        }}
      />
    </Modal>
  );
};
