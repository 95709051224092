import { Row, Select, Text } from "app/components";
import { rApp, rSavedSpreadsheets } from "app/utils/recoil";

import { colors } from "app/utils/theme";
import { get } from "lodash";
import useActiveBlock from "app/utils/useActiveBlock";
import { useRecoilValue } from "recoil";
import useSpreadsheetRequests from "app/useSpreadsheetRequests";

const SpreadsheetSelect = ({ data }) => {
  const activeBlock = useActiveBlock();

  const activeApp = useRecoilValue(rApp);

  const customBlock = get(data, "customBlock");

  const block = customBlock || activeBlock;

  const {
    handleTable,
    handleGrid,
    handleForm,
    handleInfoList,
    handleKanban,
    handleCalendar,
    handleChart,
    handleStat,
    handleCustom,
    refreshSheet,
  } = useSpreadsheetRequests();

  const savedSpreadsheets = useRecoilValue(rSavedSpreadsheets);

  const selectedSheet = savedSpreadsheets.find((s) => s.id === data.value);

  const type = get(block, "componentId");

  const changeSheet = (sheetId) => {
    data.onChange(sheetId);

    if (!data.preventExtras) {
      // Handle settings
      if (type === "Table") {
        handleTable(sheetId);
      } else if (type === "InfoList") {
        handleInfoList(sheetId);
      } else if (type === "Form") {
        handleForm(sheetId);
      } else if (type === "Kanban") {
        handleKanban(sheetId);
      } else if (type === "Grid") {
        handleGrid(sheetId);
      } else if (type === "Calendar") {
        handleCalendar(sheetId);
      } else if (type === "Chart") {
        handleChart(sheetId);
      } else if (type === "Stat") {
        handleStat(sheetId);
      } else if (type === "Custom") {
        handleCustom(sheetId);
      }
    }
  };

  const uniqueKey = `${get(block, "id")}-spreadsheet-select-${
    customBlock ? "custom" : ""
  }`;

  const dataSourceMap = {
    supabase: {
      text: "Open In Supabase",
      onClick: () =>
        window.open(
          `https://supabase.com/dashboard/project/${get(
            activeApp,
            "supabase_project_id"
          )}`
        ),
    },
    google: {
      text: "Open In Google",
      onClick: () =>
        window.open(
          `https://docs.google.com/spreadsheets/d/${selectedSheet.spreadsheet_id}/edit#gid=${selectedSheet.tab_id}`
        ),
    },
    airtable: {
      text: "Open In Airtable",
      onClick: () =>
        window.open(
          `https://airtable.com/${get(selectedSheet, [
            "config",
            "base_id",
          ])}/${get(selectedSheet, ["config", "table_id"])}`
        ),
    },
  };

  return (
    <>
      <Select
        key={uniqueKey}
        data={{
          ...data,
          label: "Data Source",
          hideEmptyItem: !data.allowEmpty,
          options: [
            ...savedSpreadsheets.map((s) => ({ label: s.title, value: s.id })),
          ],
          onChange: changeSheet,
          isGoogle: true,
        }}
      />
      <Row justifyContent="space-between" gap="30px">
        {selectedSheet && (
          <Text
            data={{
              ...get(
                dataSourceMap,
                get(selectedSheet, "service", "google"),
                {}
              ),
              margin: "5px 0 0 0",
              color: colors.primary,
              fontStyle: "headingXs",
              cursor: "pointer",
            }}
          />
        )}
        {selectedSheet && (
          <Text
            data={{
              margin: "5px 0 0 0",
              text: "Refresh Data",
              color: colors.primary,
              fontStyle: "headingXs",
              onClick: () =>
                refreshSheet(selectedSheet.id, get(activeBlock, "id")),
            }}
          />
        )}
      </Row>
    </>
  );
};

export default SpreadsheetSelect;
